import gql from "graphql-tag";

export const GET_ALL_QOPLA_PROMOTIONS = gql`
    query getAllQoplaPromotions {
        getAllQoplaPromotions {
            id
            companyIds
            targetAllCompanies
            name
            promotionMessage
            collectContactInformation
            contactButtonText
            notificationStartDate
            notificationEndDate
            disabled
            __typename
        }
    }
`;

export const GET_QOPLA_PROMOTIONS_TO_DISPLAY_BY_COMPANY_ID = gql`
    query getQoplaPromotionsByCompanyId($companyId: String!) {
        getQoplaPromotionsByCompanyId(companyId: $companyId) {
            id
            companyIds
            targetAllCompanies
            name
            promotionMessage
            collectContactInformation
            contactButtonText
            notificationStartDate
            notificationEndDate
            disabled
            __typename
        }
        getFirstShopContactDetails(companyId: $companyId) {
            name
            email
            phoneNumber
        }
    }
`;
